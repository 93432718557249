<!-- 预警详情弹窗 -->
<template>
  <div ref="parent">
    <a-modal
      centered
      :width="658"
      :title="'查看'"
      :visible="visible"
      :footer="null"
      @cancel="$emit('update:visible', false)"
      :getContainer="() => $refs.parent"
    >
      <a-spin :spinning="currentLoading">
        <section class="box-once-content show-scrollbar">
          <!-- 异地盖印预警 未补拍预警 -->
            <Offsite  :current="current" :toDetail="toDetail" :printImgOptions="printImgOptions" :cornerMark="cornerMark" :reImg="reImg"></Offsite>
          <!-- 空白页盖印预警 -->
            <Blank :current="current"  :toDetail="toDetail" :printImgOptions="printImgOptions" :cornerMark="cornerMark" :reImg="reImg"></Blank>
          <!-- 超出围栏预警 -->
            <Fence :current="current"></Fence>
          <!-- 印章柜预警 -->
            <Cabinet :current="current"></Cabinet>
          <!-- 二维码不通过预警 -->
            <QrCode :current="current" :printImgOptions="printImgOptions" :toDetail="toDetail"></QrCode>
        </section>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, ref } from 'vue'
import { warningStatusObj } from '@/utils/constData'
import { useRouter } from 'vue-router'
import PhotoView from '@/components/PhotoView'
import '@/style/jsx-label-text.scss'
import bupai from '@/assets/images/icon.png'
import cover from '@/assets/images/cover.png'
import beforeSeal from '@/assets/images/before-seal.png'
import afterSeal from '@/assets/images/after-seal.png'
import Offsite from './warnDetails/offsite.vue'
import Blank from './warnDetails/blank.vue'
import Fence from './warnDetails/fence.vue'
import Cabinet from './warnDetails/cabinet.vue'
import QrCode from './warnDetails/qrCode.vue'

export default defineComponent({
  components: {
    PhotoView,
    Offsite,
    Blank,
    Fence,
    Cabinet,
    QrCode
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => {}
    },
    currentLoading: {
      type: Boolean,
      default: true
    }
  },
  setup(props, ctx) {
    const state = reactive({
      mark: undefined
    })
    const router = useRouter()
    const cornerMark = ref({
      2: bupai, //补拍
      3: cover, //补盖
      4: beforeSeal, //用印前拍照
      5: afterSeal //用印后抓拍
    })

    // 点击流程主题跳转至详情页面
    const toDetail = documentId => {
      router.push({
        path: '/seal/apply/detail',
        query: {
          documentId: documentId
        }
      })
    }

    watch(
      () => props.visible,
      () => props.current,
      newVisible => {
        if (newVisible) {
          state.mark = undefined
        }
      }
    )

    const reImg = (e, src, sign = 'a') => {
    const parentNode = e.target.parentNode
    const tipsSpan = document.createElement(sign)
    if (sign === 'a') {
        const tipsText = document.createTextNode('点击下载该附件查看')
        tipsSpan.appendChild(tipsText)
        tipsSpan.addEventListener('click', () => {
            window.location.href = src.replace('.com//', '.com/') + '?upload=true'

            setTimeout(() => {
                cmsNotice('success', '正在为你下载，请耐心等待')
            }, 200)
        })
    } else {
        const tipsDiv = document.createElement('div')
        tipsDiv.id = 'error-imgs'
        tipsDiv.style.height = '74px'
        tipsDiv.style.width = '74px'
        const tipsImg = document.createElement('img')
        tipsImg.src = src ? require('@/assets/images/unUpload1.png') : require('@/assets/images/unUpload.png')
        tipsImg.style.width = '74px'
        tipsImg.style.height = '74px'
        tipsDiv.appendChild(tipsImg)
        parentNode.removeChild(e.target)
        parentNode.style.border = 'none'
        parentNode.style.padding = '0'
        parentNode.appendChild(tipsDiv)
    }
}
    return {
      reImg,
      cornerMark,
      state,
      warningStatusObj,
      toDetail,
      printImgOptions: {
        navbar: false,
        title: false,
        transition: false,
        viewed(e) {
          if (e.detail.originalImage.dataset.imagetype == 1) {
            this.viewer.rotateTo(0)
          }
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import url('./warnDetails/shareStyle.less');
</style>

<style lang="less">
//提示
.tooltip-custom {
  .ant-tooltip-inner {
    // 这里是框框
    color: #323233;
    background-color: #fff !important;
    border-radius: 2px;
  }
  .ant-tooltip-arrow::before {
    // 这里是小三角形
    background-color: #fff !important;
  }
}
.detail-link,
.detail-link:hover {
  color: #1890ff;
}
</style>
